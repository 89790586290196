import React from 'react';
import '../styles/About.css';
import { useLanguage } from '../context/LanguageContext';
import { getTranslation } from '../data/translation';

function About() {
  const { language } = useLanguage();
  return (
    <section className="about" id="about">
      <div className="about-container">
        <div className="about-content">
          <h1>{getTranslation(language, 'about.title1')} <span className="highlight">{getTranslation(language, 'about.title2')}</span></h1>
          <h2>{getTranslation(language, 'about.role')}</h2>
          <p>{getTranslation(language, 'about.description')}</p>
          
          <div className="terminal-window">
            <div className="terminal-header">
              <span className="terminal-button"></span>
              <span className="terminal-button"></span>
              <span className="terminal-button"></span>
                <div className="terminal-title">CV.js</div>
            </div>
            <div className="terminal-content">
              <div className="command-line">
                <span className="prompt">$</span> whoami
              </div>
              <div className="response">
                Logan DELMAIRE - Développeur Full Stack
              </div>

              <div className="command-line">
                <span className="prompt">$</span> cat {getTranslation(language, 'about.education.title')}.txt
              </div>
              <div className="response">
                • {getTranslation(language, 'about.education.step1')}<br></br>
                • {getTranslation(language, 'about.education.step2')}<br></br>
                • {getTranslation(language, 'about.education.step3')}
              </div>

              <div className="command-line">
                <span className="prompt">$</span> ls ./{getTranslation(language, 'about.mainSkills')}/
              </div>
              <div className="skills-grid">
                <span className="skill">HTML</span>
                <span className="skill">CSS</span>
                <span className="skill">JavaScript</span>
                <span className="skill">React</span>
                <span className="skill">Electron</span>
                <span className="skill">Bootstrap</span>
                <span className="skill">Next.js</span>
                <span className="skill">Node.js</span>
                <span className="skill">Docker</span>
                <span className="skill">Docker-compose</span>
                <span className="skill">PHP</span>
                <span className="skill">MySQL</span>
                <span className="skill">Git</span>
              </div>

              <div className="command-line">
                <span className="prompt">$</span> ls ./{getTranslation(language, 'about.funSkills')}/
              </div>
              <div className="skills-grid">
                <span className="skill">PYTHON</span>
                <span className="skill">C#</span>
                <span className="skill">C++</span>
                <span className="skill">LUA</span>
              </div>

              <div className="command-line">
                <span className="prompt">$</span> cat {getTranslation(language, 'about.experience.title')}.txt
              </div>
              <div className="response">
              • {getTranslation(language, 'about.experience.step1')}<br></br>
              • {getTranslation(language, 'about.experience.step2')}<br></br>
              • {getTranslation(language, 'about.experience.step3')}<br></br>
              • {getTranslation(language, 'about.experience.step4')}
              </div>

              <div className="command-line">
                <span className="prompt">$</span> cat {getTranslation(language, 'about.hobbies.title')}.txt
              </div>
              <div className="response">
                • {getTranslation(language, 'about.hobbies.step1')}<br></br>
                • {getTranslation(language, 'about.hobbies.step2')}<br></br>
                • {getTranslation(language, 'about.hobbies.step3')}<br></br>
                • {getTranslation(language, 'about.hobbies.step4')}<br></br>
                • {getTranslation(language, 'about.hobbies.step5')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
