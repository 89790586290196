import axios from 'axios';

const projectInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// Intercepteur pour gérer les erreurs
projectInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      console.error('Response Error:', error.response.data);
      console.error('Status:', error.response.status);
    }
    return Promise.reject(error);
  }
);

export default projectInstance; 