import React, { useState } from 'react';
import '../styles/ProjectPopup.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useLanguage } from '../context/LanguageContext';
import { getTranslation } from '../data/translation';

const ProjectPopup = ({ project, onClose }) => {
  const { language } = useLanguage();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === project.images.length - 1 ? 0 : prev + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? project.images.length - 1 : prev - 1
    );
  };

  return (
    <div className="project-popup-overlay">
      <div className="project-popup">
        <div className="popup-header">
          <div className="terminal-buttons">
            <span className="terminal-button"></span>
            <span className="terminal-button"></span>
            <span className="terminal-button"></span>
          </div>
          <h2>project-details.js</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        
        <div className="popup-content">
          <div className="command-line">
            <span className="prompt">$</span> cat {project.title.toLowerCase().replace(/\s+/g, '-')}.md
          </div>

          {project.images && project.images.length > 0 && (
            <div className="carousel">
              <button className="carousel-button prev" onClick={prevImage}>
                <FaArrowLeft />
              </button>
              
              <div className="project-image">
                <img 
                  src={project.images[currentImageIndex].url} 
                  alt={project.title} 
                />
              </div>
              
              <button className="carousel-button next" onClick={nextImage}>
                <FaArrowRight />
              </button>

              <div className="carousel-dots">
                {project.images.map((_, index) => (
                  <span 
                    key={index}
                    className={`dot ${index === currentImageIndex ? 'active' : ''}`}
                    onClick={() => setCurrentImageIndex(index)}
                  />
                ))}
              </div>
            </div>
          )}
          
          <div className="command-line">
            <span className="prompt">$</span> ls ./technologies/
          </div>
          <div className="technologies">
            {project.technologies && project.technologies.map((tech, index) => (
              <span key={index} className="tag">{tech}</span>
            ))}
          </div>
          
          <div className="command-line">
            <span className="prompt">$</span> cat ./description.txt
          </div>
          <div className="description">
            <p>{project.description}</p>
          </div>

          {project.features && project.features.length > 0 && (
            <>
              <div className="command-line">
                <span className="prompt">$</span> cat ./features.txt
              </div>
              <div className="features">
                <ul>
                  {project.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            </>
          )}

          {project.link && (
            <div className="project-link-container">
              <a 
                href={project.link} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="project-link"
              >
                {getTranslation(language, 'projects.viewProject')}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectPopup;