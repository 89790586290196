import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/home';
import About from './pages/about';
import Projects from './pages/projects';
import Contact from './pages/contact';
import PageTransition from './components/PageTransition';
import Install from './pages/Install';
import { LanguageProvider } from './context/LanguageContext';
import ScrollToTop from './components/ScrollToTop';
import AdminProjects from './pages/AdminProjects';
import Footer from './components/Footer';

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showTransition, setShowTransition] = useState(false);
  const [nextPath, setNextPath] = useState(null);

  useEffect(() => {
    if (nextPath) {
      setShowTransition(true);
      const timer = setTimeout(() => {
        setShowTransition(false);
        navigate(nextPath);
        setNextPath(null);
      }, 2500);
      return () => clearTimeout(timer);
    }
  }, [nextPath, navigate]);

  const handleNavigation = (path) => {
    if (path !== location.pathname) {
      setNextPath(path);
    }
  };

  const isInstallPage = location.pathname === '/';
  
  return (
    <div className="App">
      {!isInstallPage && <Navbar onNavigate={handleNavigation} />}
      <main className='main-content'>
        {showTransition && <PageTransition path={nextPath} />}
        <Routes>
          <Route path="/" element={<Install />} />
          <Route path="/home" element={<Home onNavigate={handleNavigation} />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/adminpanel" element={<AdminProjects />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

function App() {
  return (
        <LanguageProvider>
          <Router>
            <ScrollToTop />
          <AppContent />
          </Router>
        </LanguageProvider>
  );
}

export default App;
