import React, { useEffect, useState, useRef } from 'react';
import './CodeAnimation.css';

const CodeAnimation = () => {
  const [text, setText] = useState('');
  const [isRestarting, setIsRestarting] = useState(false);
  const preRef = useRef(null);
  
  const codeSnippet = `class Environment {
    constructor() {
      this.os = "Linux";
      this.distro = "Ubuntu"; 
      this.shell = "/bin/zsh";
      this.terminal = "Alacritty";
      this.editor = "VsCode";
      this.wm = "i3-gaps";
    }
  }
  // Setting up environment...
  const env = new Environment();
  class Developer {
    constructor() {
      this.name = "Logan DELMAIRE";
      this.role = "Developer";
      this.MainLanguages = [
        "HTML",
        "CSS",
        "JS",
        "React",
        "PHP",
        "MYSQL",
        "Node.js",
        "Git"
      ];
      this.ForFunLanguages = [
        "Python",
        "C#",
        "C++",
        "LUA"
      ];
    }
    code() {
      while(true) {
        if (this.hasGoodMusic() && 
            this.hasLevlUp-Drink() && 
            this.hasChuppaChups()) {
          this.writeCode();
        } else {
          console.log("Can't code without my essentials!");
        }
      }
    }
  }
  // Initializing developer...
  const me = new Developer();
  me.code();`;

  useEffect(() => {
    let currentIndex = 0;
    let intervalId;

    const startTyping = () => {
      setIsRestarting(false);
      setText('');
      
      intervalId = setInterval(() => {
        if (currentIndex < codeSnippet.length) {
          setText(codeSnippet.slice(0, currentIndex + 1));
          currentIndex++;
          
          if (preRef.current) {
            preRef.current.scrollTop = preRef.current.scrollHeight;
          }
        } else {
          clearInterval(intervalId);
          setTimeout(() => {
            setIsRestarting(true);
            currentIndex = 0;
            setTimeout(startTyping, 100);
          }, 2000);
        }
      }, 30);
    };

    startTyping();

    return () => {
      clearInterval(intervalId);
    };
  }, [codeSnippet]);

  return (
    <div className="code-animation">
      <div className="terminal-header">
        <span className="terminal-button"></span>
        <span className="terminal-button"></span>
        <span className="terminal-button"></span>
          <div className="terminal-title">Logan.js</div>
      </div>
      <pre ref={preRef}>
        <code className={isRestarting ? 'fade-out' : 'fade-in'}>
          {text}
        </code>
      </pre>
    </div>
  );
};

export default CodeAnimation; 