import React, { useState, useEffect } from 'react';
import projectAxios from '../utils/projectAxios';
import '../styles/AdminProjects.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.logandelmairedev.com';

// Définir l'ordre des technologies
const TECHNOLOGIES_ORDER = [
  "HTML",
  "CSS",
  "JavaScript",
  "TypeScript",
  "React",
  "Electron",
  "Express",
  "PHP",
  "WordPress",
  "Node.js",
  "MongoDB"
];

const LoginForm = ({ onSubmit, credentials, setCredentials }) => (
  <div className="admin-login">
    <div className="terminal-window login-terminal">
      <div className="terminal-header">
        <span className="terminal-button"></span>
        <span className="terminal-button"></span>
        <span className="terminal-button"></span>
        <div className="terminal-title">adminpanel.js</div>
      </div>
      <div className="terminal-content">
        <div className="command-line">
          <span className="prompt">$</span> sudo login admin
        </div>
        
        <form onSubmit={onSubmit} className="login-form">
          <div className="input-group">
            <label>
              <span className="prompt">username:</span>
              <input
                type="text"
                value={credentials.username}
                onChange={(e) => setCredentials({...credentials, username: e.target.value})}
                autoComplete="off"
              />
            </label>
          </div>
          
          <div className="input-group">
            <label>
              <span className="prompt">password:</span>
              <input
                type="password"
                value={credentials.password}
                onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                autoComplete="off"
              />
            </label>
          </div>

          <button type="submit" className="login-button">
            <span className="prompt">$</span> Connexion
          </button>
        </form>
      </div>
    </div>
  </div>
);

const Modal = ({ isOpen, onClose, children, title }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="terminal-window modal-content">
        <div className="terminal-header">
          <span className="terminal-button"></span>
          <span className="terminal-button"></span>
          <span className="terminal-button"></span>
          <div className="terminal-title">{title}</div>
        </div>
        <div className="terminal-content">
          {children}
        </div>
      </div>
    </div>
  );
};

const ProjectForm = ({ onSubmit, project, setProject, isEditing, onClose, api }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  
  useEffect(() => {
    console.log("Images du projet:", project.images);
  }, [project.images]);

  const handleTechClick = (tech) => {
    const currentTechs = project.technologies ? project.technologies.split(',').map(t => t.trim()) : [];
    const techExists = currentTechs.includes(tech);
    
    let newTechs;
    if (techExists) {
      newTechs = currentTechs.filter(t => t !== tech);
    } else {
      newTechs = [...currentTechs, tech];
    }
    
    // Trier les technologies selon l'ordre défini
    newTechs.sort((a, b) => {
      return TECHNOLOGIES_ORDER.indexOf(a) - TECHNOLOGIES_ORDER.indexOf(b);
    });
    
    setProject({
      ...project,
      technologies: newTechs.join(', ')
    });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(prev => [...prev, ...files]);
  };

  const removeImage = async (public_id) => {
    if (isEditing && project._id) {
      try {
        // Extraire uniquement l'ID de l'image sans le chemin du dossier
        const cloudinaryId = public_id.split('/').pop();
        await api.delete(`/api/projects/${project._id}/images/${cloudinaryId}`);
        setProject(prev => ({
          ...prev,
          images: prev.images.filter(img => img.public_id !== public_id)
        }));
      } catch (error) {
        console.error('Erreur lors de la suppression de l\'image:', error);
      }
    } else {
      setSelectedFiles(prev => prev.filter(file => file.name !== public_id));
    }
  };

  return (
    <form onSubmit={(e) => onSubmit(e, selectedFiles)}>
      <input
        type="text"
        placeholder="Titre du projet"
        value={project.title}
        onChange={(e) => setProject({...project, title: e.target.value})}
        required
      />

      <div className="tech-selector">
        <p>Technologies :</p>
        <div className="tech-buttons">
          {TECHNOLOGIES_ORDER.map(tech => (
            <button
              key={tech}
              type="button"
              className={`tech-button ${project.technologies?.includes(tech) ? 'active' : ''}`}
              onClick={() => handleTechClick(tech)}
            >
              {tech}
            </button>
          ))}
        </div>
      </div>

      <textarea
        placeholder="Description du projet"
        value={project.description}
        onChange={(e) => setProject({...project, description: e.target.value})}
        required
        rows={4}
      />

      <textarea
        placeholder="Fonctionnalités (une par ligne)"
        value={project.features}
        onChange={(e) => setProject({...project, features: e.target.value})}
        rows={4}
      />

      <div className="image-upload">
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          multiple
          id="image-input"
          style={{ display: 'none' }}
        />
        <label htmlFor="image-input" className="project-link">
          {isEditing ? 'Ajouter plus d\'images' : 'Ajouter des images'}
        </label>

        <div className="images-preview">
          {isEditing && project.images && project.images.length > 0 && (
            <>
              <h4>Images existantes :</h4>
              <div className="existing-images">
                {project.images.map((img, index) => (
                  <div key={`existing-${index}`} className="image-preview-item">
                    <img 
                      src={img.url}
                      alt={`Image ${index + 1}`} 
                    />
                    <button 
                      type="button" 
                      onClick={() => removeImage(img.public_id)}
                      className="remove-image"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </>
          )}

          {selectedFiles.length > 0 && (
            <>
              <h4>Nouvelles images :</h4>
              <div className="new-images">
                {selectedFiles.map((file, index) => (
                  <div key={`new-${index}`} className="image-preview-item">
                    <img 
                      src={URL.createObjectURL(file)} 
                      alt={`Nouvelle image ${index + 1}`} 
                    />
                    <button 
                      type="button" 
                      onClick={() => removeImage(file.name)}
                      className="remove-image"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      <input
        type="text"
        placeholder="Lien du projet (optionnel)"
        value={project.link}
        onChange={(e) => setProject({...project, link: e.target.value})}
      />

      <div className="form-buttons">
        <button type="submit" className="project-link">
          {isEditing ? 'Mettre à jour' : 'Ajouter le projet'}
        </button>
        <button type="button" onClick={onClose} className="project-link delete">
          Annuler
        </button>
      </div>
    </form>
  );
};

const ProjectList = ({ projects, onDelete, onEdit }) => (
  <div className="projects-grid">
    {projects.map(project => (
      <div key={project._id} className="project-card">
        <h3>{project.title}</h3>
        <div className="project-tags">
          {project.technologies.map((tech, i) => (
            <span key={i} className="tag">{tech}</span>
          ))}
        </div>
        <p>{project.description}</p>
        <div className="project-actions">
          <button onClick={() => onEdit(project)} className="project-link">
            Éditer
          </button>
          <button onClick={() => onDelete(project._id)} className="project-link delete">
            Supprimer
          </button>
        </div>
      </div>
    ))}
  </div>
);

const AdminProjects = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [projects, setProjects] = useState([]);
  const [loginData, setLoginData] = useState({
    username: '',
    password: ''
  });
  const [newProject, setNewProject] = useState({
    title: '',
    technologies: '',
    description: '',
    features: '',
    image: '',
    link: ''
  });
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [error, setError] = useState(null);

  // Déplacer la création de l'instance api ici
  const api = projectAxios.create({
    baseURL: API_URL,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });

  // Déplacer la fonction fetchProjects en dehors du useEffect
  const fetchProjects = async () => {
    try {
      const response = await projectAxios.get('/api/admin/projects');
      setProjects(response.data);
    } catch (error) {
      if (error.response?.status === 401) {
        setIsLoggedIn(false);
      }
      console.error('Erreur:', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleLoginChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await projectAxios.post('/api/admin/projects/auth/login', {
        username: loginData.username,
        password: loginData.password
      });

      if (response.data.token) {
        console.log('Connexion réussie, token reçu');
        localStorage.setItem('token', response.data.token);
        setIsLoggedIn(true);
      }
    } catch (error) {
      console.error('Erreur de connexion:', error);
    }
  };

  const handleAddProject = async (e, selectedFiles) => {
    e.preventDefault();
    try {
      // Créer un FormData pour gérer le fichier
      const formData = new FormData();
      formData.append('title', newProject.title);
      formData.append('description', newProject.description);
      formData.append('technologies', newProject.technologies);
      formData.append('features', newProject.features);
      formData.append('link', newProject.link);
      
      if (selectedFiles.length > 0) {
        selectedFiles.forEach((file, index) => {
          formData.append('images', file);
        });
      }

      // Configuration spéciale pour FormData
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      };

      await projectAxios.post(`${API_URL}/api/projects`, formData, config);
      await fetchProjects();
      setIsAddModalOpen(false);
      setNewProject({
        title: '',
        technologies: '',
        description: '',
        features: '',
        image: '',
        link: ''
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout du projet:', error);
    }
  };

  const handleDeleteProject = async (id) => {
    try {
      await api.delete(`/api/projects/${id}`);
      fetchProjects();
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
    }
  };

  const handleEdit = (project) => {
    console.log("Projet à éditer:", project);
    setEditingId(project._id);

    // Trier les technologies selon l'ordre défini
    const sortedTechnologies = [...project.technologies].sort((a, b) => {
      return TECHNOLOGIES_ORDER.indexOf(a) - TECHNOLOGIES_ORDER.indexOf(b);
    });

    setEditingProject({
      ...project,
      technologies: sortedTechnologies.join(', '),
      features: Array.isArray(project.features) ? project.features.join('\n') : '',
      images: project.images || []
    });
    setIsEditModalOpen(true);
  };

  const handleUpdateProject = async (e, selectedFiles) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('title', editingProject.title);
      formData.append('description', editingProject.description);
      formData.append('technologies', editingProject.technologies);
      formData.append('features', editingProject.features);
      formData.append('link', editingProject.link);
      
      if (selectedFiles.length > 0) {
        selectedFiles.forEach((file, index) => {
          formData.append('images', file);
        });
      }

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      };

      await projectAxios.put(`${API_URL}/api/projects/${editingId}`, formData, config);
      await fetchProjects();
      setIsEditModalOpen(false);
      setEditingProject(null);
      setEditingId(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour:', error);
    }
  };

  if (!isLoggedIn) {
    return (
      <LoginForm 
        onSubmit={handleLogin}
        credentials={loginData}
        setCredentials={setLoginData}
      />
    );
  }

  return (
    <section className="admin-projects">
      <div className="terminal-window">
        <div className="terminal-header">
          <span className="terminal-button"></span>
          <span className="terminal-button"></span>
          <span className="terminal-button"></span>
          <div className="terminal-title">admin-projects.js</div>
        </div>
        <div className="terminal-content">
          <div className="command-line">
            <span className="prompt">$</span> ls ./admin/projects/
          </div>

          <div className="dashboard-header">
            <h2>Gestion des <span className="highlight">Projets</span></h2>
            <button 
              onClick={() => {
                setNewProject({
                  title: '',
                  technologies: '',
                  description: '',
                  features: '',
                  image: '',
                  link: ''
                });
                setIsAddModalOpen(true);
              }}
              className="project-link new-project-btn"
            >
              + Nouveau Projet
            </button>
          </div>

          <ProjectList 
            projects={projects}
            onDelete={handleDeleteProject}
            onEdit={handleEdit}
          />

          <div className="command-line">
            <span className="prompt">$</span> echo "Total projects: {projects.length}"
          </div>
        </div>
      </div>

      {/* Modales */}
      <Modal 
        isOpen={isAddModalOpen} 
        onClose={() => setIsAddModalOpen(false)}
        title="nouveau-projet.js"
      >
        <div className="command-line">
          <span className="prompt">$</span> touch nouveau-projet.js
        </div>
        <ProjectForm 
          onSubmit={handleAddProject}
          project={newProject}
          setProject={setNewProject}
          isEditing={false}
          onClose={() => setIsAddModalOpen(false)}
          api={api}
        />
      </Modal>

      <Modal 
        isOpen={isEditModalOpen} 
        onClose={() => {
          setIsEditModalOpen(false);
          setEditingProject(null);
        }}
        title="edit-projet.js"
      >
        <div className="command-line">
          <span className="prompt">$</span> vim edit-projet.js
        </div>
        <ProjectForm 
          onSubmit={handleUpdateProject}
          project={editingProject}
          setProject={setEditingProject}
          isEditing={true}
          onClose={() => {
            setIsEditModalOpen(false);
            setEditingProject(null);
          }}
          api={api}
        />
      </Modal>
    </section>
  );
};

export default AdminProjects; 