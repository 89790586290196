import React, { useState } from 'react';
import '../styles/Contact.css';
import { useLanguage } from '../context/LanguageContext';
import { getTranslation } from '../data/translation';
import axios from 'axios';

function Contact() {
  const API_URL = process.env.REACT_APP_API_URL;

  const { language } = useLanguage();
  const [formData, setFormData] = useState({
    subject: '',
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus('');

    console.log('Tentative d\'envoi du formulaire avec les données:', formData);
    console.log('URL de l\'API:', `${API_URL}/api/contact`);

    try {
      console.log('Envoi de la requête...');
      const response = await axios.post(`${API_URL}/api/contact`, formData);
      console.log('Réponse reçue:', response);

      if (response.status === 200) {
        console.log('Succès: Message envoyé');
        setSubmitStatus('success');
        setFormData({ subject: '', name: '', email: '', message: '' });
      } else {
        console.error('Erreur: Statut inattendu', response.status);
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Erreur détaillée:', error);
      console.error('Message d\'erreur:', error.message);
      if (error.response) {
        console.error('Réponse d\'erreur:', error.response.data);
        console.error('Statut:', error.response.status);
      }
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
      console.log('Formulaire traité - État final:', {
        isSubmitting: false,
        submitStatus: submitStatus,
        formData: formData
      });
    }
  };

  return (
    <section className="contact">
      <div className="contact-container">
        <div className="contact-content">
          <h1>{getTranslation(language, 'contact.title1')} <span className="highlight">{getTranslation(language, 'contact.title2')}</span></h1>
          <h2>{getTranslation(language, 'contact.subtitle')}</h2>

          <div className="terminal-window">
            <div className="terminal-header">
              <span className="terminal-button"></span>
              <span className="terminal-button"></span>
              <span className="terminal-button"></span>
              <div className="terminal-title">contact.js</div>
            </div>
            <div className="terminal-content">
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="command-line">
                  <span className="prompt">$</span> ./{getTranslation(language, 'contact.form.title')}.sh
                </div>

                <div className="form-group">
                  <div className="command-line">
                    <span className="prompt">$</span> {getTranslation(language, 'contact.form.subject_label')}:
                  </div>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder={getTranslation(language, 'contact.form.subject_input')}
                    required
                  />
                </div>

                <div className="form-group">
                  <div className="command-line">
                    <span className="prompt">$</span> {getTranslation(language, 'contact.form.name_label')}:
                  </div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder={getTranslation(language, 'contact.form.name_input')}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <div className="command-line">
                    <span className="prompt">$</span> {getTranslation(language, 'contact.form.email_label')}:
                  </div>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder={getTranslation(language, 'contact.form.email_input')}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <div className="command-line">
                    <span className="prompt">$</span> {getTranslation(language, 'contact.form.message_label')}:
                  </div>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="6"
                    placeholder={getTranslation(language, 'contact.form.message_input')}
                    required
                  />
                </div>
                
                <div className="command-line">
                  <button type="submit" className="submit-btn" disabled={isSubmitting}>
                    <span className="prompt">$</span> 
                    {isSubmitting 
                      ? getTranslation(language, 'contact.form.sending') 
                      : `${getTranslation(language, 'contact.form.send')}.exe`}
                  </button>
                </div>

                {submitStatus && (
                  <div className={`submit-status ${submitStatus}`}>
                    {submitStatus === 'success' 
                      ? getTranslation(language, 'contact.form.success')
                      : getTranslation(language, 'contact.form.error')}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;